.App-logo {
  height: 25vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-scale infinite 2.5s linear;
  }
}

/* responsive ipad and above devices */
@media only screen and (min-width: 768px) {
  .App-logo {
    height: 15vmin;
  }
}

/* animations */
@keyframes App-logo-scale {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.5);
  }
  40% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  60% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.5);
  }
  90% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(10);
    opacity: 0;
  }
}
