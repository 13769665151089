.section-title {
  position: relative;
  padding: 115px 0 35px 0;
  text-align: center;
}

.section-title::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100px;
  top: 0;
  left: 50%;
  margin-left: 1px;
  background: #000000;
}

.mheight {
  min-height: 300px;
}
.aboutimg1 {
  objectfit: "cover";
}
.map-cont {
  width: 100vw;
}

@media only screen and (max-width: 990px) {
  .aboutimg1 {
    height: 100%;
  }
}
