#pcontainer {
  box-shadow: 11px 10px 8px 0px rgb(128 128 128 / 76%);
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
  margin: 16px;
  height: 550px;
  max-height: 550px;
  width: 300px;
  min-width: 280px;
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  z-index: 0;
  line-height: 1.1;
}
#pcontainer li {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
#pcontainer li strong {
  flex-basis: 40%;
}
#pcontainer li span {
  flex-basis: 60%;
}
/* 	Product details  */
.productdetails {
  position: relative;
  text-align: left;
  overflow: hidden;
  padding: 30px 15px;
  height: 100%;
  flex-basis: 55%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.productdetails ul {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: nowrap;
}

/* 	Product Name */
#pcontainer .productimage h1 {
  font-family: "DosisBold";
  font-size: 25px;
  color: #344055;
  margin: 0px auto;
  line-height: 1.3;
  text-align: center;
}
.information {
  text-align: center;
  line-height: 2;
  font-size: 12px;
  padding: 10px 18px;
}

#pcontainer .productdetails h2:before {
  position: absolute;
  content: "";
  right: 10%;
  top: 9%;
  transform: translate(25px, -15px);
  display: inline-block;
  background: #ffa69e;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px;
  color: #fff;
  margin: 0;
  animation: chan-sh 6s ease infinite;
}

@keyframes chan-sh {
  from {
    content: "Click on above Pic";
  }
  to {
    content: "For Details";
  }
}

/*Product Rating  */
.hint-star {
  display: inline-block;
  margin-left: 0.5em;
  color: gold;
  width: 50%;
}

/* The most important information about the product */
#pcontainer .productimage > p {
  text-align: center;
  font-size: 18px;
  color: #7d7d7d;
}

/* control */

/* the Button */
.btn {
  transform: translateY(0px);
  transition: 0.3s linear;
  background: #49c608;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  outline: none;
  border: none;
  color: #eee;
  padding: 0;
  margin: 0;
}

.btn:hover {
  transform: translateY(-4px);
}

.btn span {
  transition: transform 0.3s;
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.2em;
  margin: 0;
}
/* shopping cart icon */
.btn .price,
.shopping-cart {
  background: #333;
  border: 0;
  margin: 0;
}

.btn .price {
  transform: translateX(-10%);
  padding-right: 15px;
}

/* the Icon */
.btn .shopping-cart {
  transform: translateX(-100%);
  position: absolute;
  background: #333;
  z-index: 1;
  left: 0;
  top: 0;
}

/* buy */
.btn .buy {
  z-index: 3;
  font-weight: bolder;
}

.btn:hover .price {
  transform: translateX(-110%);
}

.btn:hover .shopping-cart {
  transform: translateX(0%);
}

/* product image  */
.productimage {
  transition: all 0.3s ease-out;
  display: inline-block;
  position: relative;
  overflow: hidden;
  height: 100%;
  flex-basis: 45%;
  display: inline-block;
}

#pcontainer img {
  width: 100%;
  height: 100%;
}

.info {
  background: rgba(27, 26, 26, 0.9);
  transition: all 0.3s ease-out;
  transform: translateX(-100%);
  position: absolute;
  line-height: 1.9;
  text-align: left;
  font-size: 100%;
  cursor: no-drop;
  color: #fff;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.productdetails h2 {
  font-size: 22px;
  line-height: 1.1;
  font-family: "DosisBold";
  width: 100%;
  text-align: center;
}
.productimage:hover .info {
  transform: translateX(0);
}

.info ul li {
  transition: 0.3s ease;
  width: 90%;
  margin: auto;
}
.info ul li:hover {
  transform: translateX(15px) scale(1.1);
}

.productimage:hover img {
  transition: all 0.3s ease-out;
}
.productimage:hover img {
  transform: scale(1.2, 1.2);
}

/* Desktop and ipad styles */
@media only screen and (min-width: 730px) {
  /* #pcontainer {
    height: 350px;
    width: 700px;
    flex-direction: row;
  }
  .productdetails {
    height: auto;
  }
  .control {
    bottom: 10%;
  } */
  @keyframes chan-sh {
    from {
      content: "Hover on Pic";
    }
    to {
      content: "For Details";
    }
  }
}
