/* #7cfe61 */
/* #0096ff */
strong {
  font-family: "DosisBold";
}
.App,
.AppLoader {
  min-height: 100vh;
  background-color: #fff;
  overflow-x: hidden;
}
.AppLoader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.App {
}

.font-face-gm {
  font-family: "DosisBold";
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.pagescontainer {
  padding-top: 50px;
  width: 100vw;
  margin: 0 auto;
}

#contactLogo {
  position: fixed;
  right: 2%;
  bottom: 2%;
  cursor: pointer;
  font-size: 20px;
  background: url(./assets/WhatsApp_icon.png);
  background-size: 40px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 99;
  width: 40px;
  height: 40px;
}

/* Desktop and ipad styles */
@media only screen and (min-width: 600px) {
  .App {
    background-size: 220px;
  }
  .desktopHide {
    display: none;
  }
  #contactLogo {
    background-size: 60px;
    width: 60px;
    height: 60px;
    right: 3%;
  }
}

.w-1000 {
  max-width: 1000px;
  margin: 0 auto;
}
