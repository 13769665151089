@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,700;0,900;1,900&display=swap");

.to {
  font-family: "Lato", sans-serif;
  font-size: 40px;
  color: #019836 !important;
}

.banner {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  height: 100%;
  justify-content: space-evenly;
}
.banner div {
  text-align: center;
}
.text-center-sm {
  text-align: center;
}
.class-center {
  margin: 0 auto;
}
.bannerlogo {
  padding: 20px 0 0;
  width: 300px;
  margin: 0 auto;
  animation: pulse 0.5s infinite alternate;
}
.logop1 {
  font-family: "DosisBold";
  color: green;
  font-size: 28px;
}
.logop2 {
  font-family: "DosisBold";
  font-size: 28px;
}

/* animations */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}

/* Desktop and ipad styles */
@media only screen and (min-width: 600px) {
  .banner {
    flex-direction: row;
    justify-content: space-between;
  }
  .bannerlogo {
    margin: 0;
  }
  .text-center-sm {
    text-align: left;
  }
}
/* Desktop */
@media only screen and (min-width: 1000px) {
}
.totext {
  font-family: "Lato", sans-serif;
  background: rgb(22, 45, 205);
  background: linear-gradient(
    180deg,
    rgba(22, 45, 205, 1) 0%,
    rgba(0, 132, 252, 0.9559174011401436) 42%,
    rgba(0, 212, 255, 1) 100%
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact_us {
  text-decoration: none;
}
