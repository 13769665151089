footer {
  padding: 30px 20px;
  background-color: #575a6c;
  color: #fff;
  width: 100vw;
}
#footercontainer {
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  margin: 0 auto;
}
#footercontainer .ftleft,
#footercontainer .ftright {
  flex-basis: 50%;
}

footer .menuList {
  transition: all 0.3s ease-in-out;
  color: #fff;
}
footer .menuList:hover {
  transition: all 0.3s ease-in-out;
  color: #fff;
}
.location,
.mobile,
.mail {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
/* .location span:first-child,
.mobile span:first-child,
.mail span:first-child {
  flex-basis: 20%;
} */
.icon {
  width: 27px;
  height: auto;
  margin: 0 20px 0px 0;
}

.about,
.description,
.socialMedia {
  padding-top: 10px;
  line-height: 1.2;
}
.socialMedia {
  width: 100px;
  height: 35px;
}

.socialMedia a {
  display: inline-block;
  width: 35px;
}

.footerLogo {
  width: 150px;
  height: auto;
}

/* Desktop and ipad styles */
@media only screen and (max-width: 600px) {
  #footercontainer {
    flex-direction: column;
  }
}
.mail span a,
.mobile span a,
.location span a {
  color: white !important;
  text-decoration: none;
}

.location span a {
}
