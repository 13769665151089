@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: "DosisRegular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "DosisBold";
  src: local("DosisBold"),
    url("./fonts/Dosis/Dosis-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "DosisExtraBold";
  src: local("DosisExtraBold"),
    url("./fonts/Dosis/Dosis-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "DosisLight";
  src: local("DosisLight"),
    url("./fonts/Dosis/Dosis-Light.ttf") format("truetype");
}
@font-face {
  font-family: "DosisRegular";
  src: local("DosisRegular"),
    url("./fonts/Dosis/Dosis-Regular.ttf") format("truetype");
}
