#mainNav {
  z-index: 999;
  height: 50px;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  /* background-color: #fff; */
}
nav.navigation {
  display: flex;
  padding: 5px;
  backdrop-filter: blur(5px);
  height: 50px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  background-color: #fff;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
.Brandname {
  text-transform: uppercase;
  font-family: DosisExtraBold;
}
.Brandname img {
  width: auto !important;
  height: 100%;
}

.hamburger {
  height: 25px;
  width: auto;
  border-radius: 4px;
  margin-top: 8.5px;
}

/* Desktop and ipad styles */
@media only screen and (min-width: 600px) {
  nav.navigation {
    padding: 5px 30px;
  }
  /* .Brandname:hover img {
  } */
}

/* Desktop and ipad styles */
@media only screen and (min-width: 1200px) {
  #mainNav {
    height: 50px;
    width: 100vw;
    background-color: #fff;
    position: fixed;
  }
  nav.navigation {
    transform: translateX(-50%);
    left: 50%;
    max-width: 1200px;
    width: 1200px;
  }
}

.hamburgerEvent {
  pointer-events: none;
}
