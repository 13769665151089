.menu {
  display: flex;
  position: absolute;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: rgba(255, 255, 255, 0.822);
  color: rgb(8, 8, 8);
  align-items: center;
  /* border-radius: 20px; */
  font-size: 30px;
  font-family: DosisBold;
  backdrop-filter: blur(2px);
  text-decoration: none;
  top: -1110%;
  left: 0;
  animation: slide-down 0.5s 0s forwards ease-in-out;
  z-index: 99999;
}
.slideUp {
  animation: slide-up 0.5s 0s forwards ease-in-out !important;
}
.animationNone {
  animation: none;
}

@keyframes slide-up {
  from {
    top: 0;
  }
  to {
    top: -1500%;
  }
}

@keyframes slide-down {
  from {
    top: -1500%;
  }
  to {
    top: 0%;
  }
}
.menuClose {
  position: absolute;
  top: 2.5vh;
  right: 3vw;
  height: 25px;
  width: auto;
}
.menuLogo {
  width: 210px;
  height: auto;
}
nav .menuList {
  transition: all 0.3s ease-in-out;
  display: block;
}
nav .menuList:hover {
  transition: all 0.3s ease-in-out;
  transform: skew(5deg, 5deg);
}
.menuactive {
  color: #0096ff;
}

/* Desktop and ipad styles */
@media only screen and (min-width: 600px) {
  .menu {
    position: static;
    flex-direction: row;
    width: auto;
    height: auto;
    font-size: 20px;
    background-color: transparent;
  }
  .menu a {
    margin-left: 20px;
  }
}
