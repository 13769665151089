@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,700;0,900;1,900&display=swap");

.container {
  font-family: "Lato", sans-serif;
  font-size: 60px;
  color: #0678f3;
  background: rgb(22, 45, 205);
  background: linear-gradient(
    180deg,
    rgba(22, 45, 205, 1) 0%,
    rgba(0, 132, 252, 0.9559174011401436) 42%,
    rgba(0, 212, 255, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 25px;
  /* text-align: left !important; */
}
.featuredtext {
  line-height: 1.1;
}
