.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
}
.popup_inner {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* margin: auto; */
  /* background: white; */
  padding: 10px;
  height: fit-content;
  width: fit-content;
}
.skip {
  position: absolute;
  top: 15px;
  right: 17px;
  text-decoration: none;
  z-index: 99999;
  color: #0096ff;
  font-size: 17px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.skip:hover {
  transition: all 0.5s ease-in-out;
  color: #ff0000;
}
.green {
  color: green;
}
.blue {
  color: #0096ff;
  animation: pulse 0.5s infinite alternate;
}
.popup_inner .container {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
  min-width: 300px;
}

.form-container h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.form-container {
  display: flex;
}

.left-container {
  flex: 1;
  height: 480px;
  background-color: #0096ff;
}

.right-container {
  display: flex;
  flex: 1;
  height: 460px;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
}

.left-container {
  display: flex;
  flex: 1;
  height: 480px;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.left-container p {
  font-size: 0.9rem;
}

.right-inner-container {
  width: 70%;
  height: 90%;
  text-align: center;
}
.right-inner-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.left-inner-container {
  height: 50%;
  width: 80%;
  text-align: center;
  line-height: 22px;
}

.popup input,
.popup textarea {
  background-color: #eee !important;
  border: none !important;
  padding: 12px 15px !important;
  margin: 8px 0 !important;
  width: 100% !important;
  font-size: 0.8rem !important;
  max-width: 100% !important;
  max-height: 120px !important;
}

.popup_inner input:focus,
.popup_inner textarea:focus {
  outline: 1px solid #0096ff;
}

.popup_inner button {
  border-radius: 20px;
  border: 1px solid #0096ff;
  background-color: #0096ff;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}

.popup_inner button:hover {
  opacity: 0.7;
}

@media only screen and (max-width: 600px) {
  .popup_inner .left-container {
    display: none;
  }

  .lg-view {
    display: none;
  }
}
@media only screen and (min-width: 600px) {
  .sm-view {
    display: none;
  }
}
.popup_inner form p {
  text-align: left;
}

@media only screen and (max-width: 800px) {
  .popup_inner .container {
    width: 90vw !important;
  }
}
